@import '~antd/dist/antd.less';

.root {
  height: 100vh;
  min-height: 600px;
  min-width: 400px;

  & .header {
    border-bottom-width: @border-width-base;
    border-bottom-color: @border-color-split;
    box-shadow: @page-header-border-shadow;
  }

  & .content {
    display: flex;
    flex-direction: column;


    & .content_row {
      flex: auto;

      & .content_col {
        flex: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        // Ant Row adds 39px of vertical padding already
        padding: 0 39px;
        background: @layout-body-background;
      }
    }
  }
}

@primary-color: #1871ed;@error-color: #D93616;@success-color: #63BE09;@warning-color: #FFBA00;@table-header-bg: transparent;@card-radius: 8px;@card-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 8px 0px rgba(0, 0, 0, 0.1);@layout-header-background: #FFFFFF;@layout-body-background: #F2F2F2;@page-header-heading-title: 24px;@page-header-padding-vertical: 24px;@page-header-border-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);@btn-border-radius-base: 6px;